import { Link } from 'gatsby';
import * as React from 'react';

import Layout from '../../components/layout';
import Seo from '../../components/seo';
import './index.css';
import { StaticImage } from 'gatsby-plugin-image';

const himorHer: { q: string; a: 'h' | 'n' }[] = [
  { q: 'Who is the most patient?', a: 'h' },
  { q: 'Is the bigger spender?', a: 'n' },
  { q: 'Rescues the spiders?', a: 'n' },
  { q: 'Is the better cook?', a: 'n' },
  { q: 'Falls asleep first?', a: 'n' },
  { q: 'Is more organised?', a: 'h' },
  { q: 'Is the bigger baby when sick?', a: 'h' },
  { q: 'Has kissed more people?', a: 'h' },
  { q: 'Is more competitive?', a: 'n' },
  { q: 'Initiated the first kiss?', a: 'h' },
  { q: 'More likely to lose their keys?', a: 'n' },
  { q: "Doesn't put their clothes away?", a: 'h' },
  { q: 'Can hold their drink better?', a: 'h' },
  { q: 'Is the morning person?', a: 'n' },
  { q: 'Eats the most junk food?', a: 'n' },
  { q: 'Is the messier eater?', a: 'h' },
];

const crosswordAcross: { n: number; q: string; l: number; a: string }[] = [
  {
    n: 1,
    q: 'What animal did Heather make a bronze statue of at university?',
    l: 4,
    a: 'lion',
  },
  { n: 6, q: "What are Nick's favourite Maoam sweet?", l: 8, a: 'pinballs' },
  { n: 7, q: 'How many cars do we own?', l: 3, a: 'one' },
  {
    n: 8,
    q: 'Which film that Heather worked on has the lowest IMDb score?',
    l: 4,
    a: 'cats',
  },
  {
    n: 10,
    q: "What monster featured in Heather's first film job?",
    l: 6,
    a: 'zombie',
  },
  { n: 11, q: 'How many tattoos does Heather have?', l: 3, a: 'two' },
  {
    n: 13,
    q: 'Which anise-flavoured liqueur is one of our cats is named after?',
    l: 7,
    a: 'sambuca',
  },
  { n: 15, q: 'Which county did we meet in?', l: 15, a: 'nottinghamshire' },
  { n: 17, q: 'How many piercings does Nick have?', l: 4, a: 'none' },
  { n: 18, q: "What is Nick's middle name?", l: 5, a: 'james' },
  {
    n: 20,
    q: 'In which body part is Heather missing joints?',
    l: 5,
    a: 'thumb',
  },
];

const crosswordDown: { n: number; q: string; l: number[]; a: string }[] = [
  {
    n: 2,
    q: 'What country would we like to visit for our honeymoon?',
    l: [7],
    a: 'iceland',
  },
  {
    n: 3,
    q: 'Which plant featured in our Bristol street address?',
    l: [11],
    a: 'honeysuckle',
  },
  {
    n: 4,
    q: 'Which type of game do we own more of, board games or video games?',
    l: [5],
    a: 'video',
  },
  {
    n: 5,
    q: 'Which beverage distilled from the blue agave plant is one of our cats is named after?',
    l: [7],
    a: 'tequila',
  },
  {
    n: 9,
    q: 'What is our favourite country to visit together?',
    l: [6],
    a: 'sweden',
  },
  {
    n: 11,
    q: 'Which anniversary are we celebrating today?',
    l: [5],
    a: 'tenth',
  },
  {
    n: 12,
    q: 'What type of ball did we play with on the day we met? ',
    l: [4, 4],
    a: 'ping pong',
  },
  {
    n: 14,
    q: 'What food did Heather gift to Nick on the day we first met?',
    l: [5],
    a: 'pizza',
  },
  { n: 16, q: "What is Nick's birth month?", l: [5], a: 'march' },
  {
    n: 19,
    q: 'How many years had we been together on the day we got engaged?',
    l: [5],
    a: 'eight',
  },
];

const GamesPage = () => {
  return (
    <Layout>
      <Seo title="Games" />
      <div className="FaqContent">
        <h1 style={{ marginBottom: '2.5rem' }}>Games</h1>

        <section>
          <h2>HIM OR HER</h2>
          <p>
            Mark with a 'H' or an 'N' who you think the prompt is relevant to.
          </p>
          <div className="answers-container">
            <ol>
              {himorHer.map(({ q, a }) => (
                <li>
                  <div className="spreadit">
                    <span className="question">{q}</span>
                    <span>{a === 'h' ? 'Heather' : 'Nick'}</span>
                  </div>
                </li>
              ))}
            </ol>
          </div>
        </section>
        <hr />

        <section>
          <h2>CROSSWORD</h2>

          <div className="crossword-container">
            <StaticImage
              src="../../assets/images/crossword.webp"
              placeholder="none"
              width={500}
              className="crossword"
              alt={'The crossword template'}
            />
          </div>
          <div className="crosswordAnswers">
            <div className="column">
              <h3 className="crosswordHeader">ACROSS</h3>
              <ol>
                {crosswordAcross.map(({ n, q, l, a }) => (
                  <li data-content={`${n}. `}>{a.toUpperCase()}</li>
                ))}
              </ol>
            </div>
            <div className="column">
              <h3 className="crosswordHeader">DOWN</h3>
              <ol>
                {crosswordDown.map(({ n, a }) => (
                  <li data-content={`${n}. `}>{a.toUpperCase()}</li>
                ))}
              </ol>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default GamesPage;
